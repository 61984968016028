"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", height: "100%", width: "100%", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#34313F", d: "M105.4,113.9h25.2c7.2,0,11.7-3.9,11.7-10.3c0-6-4.5-10.3-11.7-10.3h-25.2c-0.8,0-1.6,0.7-1.6,1.4v17.7 C103.9,113.3,104.5,113.9,105.4,113.9z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#34313F", d: "M105.4,154.7h26.1c8,0,12.8-4.1,12.8-11.2c0-6.2-4.5-11.2-12.8-11.2h-26.1c-0.8,0-1.6,0.7-1.6,1.4v19.5 C103.9,154.1,104.5,154.7,105.4,154.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#34313F", d: "M197.7,0.4l-74.6,74.4h12.2c19,0,30.3,12,30.3,25c0,10.3-6.9,17.8-14.3,21.2c-1.2,0.5-1.2,2.2,0.1,2.6 c8.6,3.3,14.7,12.3,14.7,22.9c0,14.8-9.9,26.7-29.2,26.7H84c-0.8,0-1.6-0.7-1.6-1.4v-56.4L0.5,197c-1.1,1.1-0.4,3,1.2,3h196.9 c0.7,0,1.3-0.6,1.3-1.3V1.5C200.3,0.2,198.6-0.5,197.7,0.4z" })] }) })));
}
exports.default = Svg;
