"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { fill: "#002A45", width: 200, height: 200 }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M152.8,64.7c-1.3-1.8-3.9-2.3-5.8-1l-102.7,71c0.8,1.1,2.1,1.8,3.4,1.8h101.7c2.3,0,4.2-1.9,4.2-4.2 c0,0,0,0,0-0.1V67.1C153.6,66.3,153.3,65.5,152.8,64.7" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M50.1,63.7c-1.9-1.3-4.5-0.8-5.8,1c-0.5,0.7-0.7,1.5-0.7,2.3v65.3l48.8-39.4L50.1,63.7z" })] })] })));
}
exports.default = Svg;
