"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", height: "100%", width: "100%", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { fill: "#3E3C42", width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { id: "Fill-15_2_", fill: "#FFFFFF", d: "M58.9,146.2v-13.5h11.1V67.3H58.9V53.8h44.3c20.3,0,32.7,9.1,32.7,24.9 c0,10.4-6.3,16.7-14.2,19.8c11.4,3,19.5,10.8,19.5,21.5c0,16.9-13.5,26.3-35.7,26.3H58.9L58.9,146.2L58.9,146.2z M85.1,67.3v25.2 h16.5c12.4,0,18.8-4.5,18.8-12.6c0-8.3-6.6-12.6-19-12.6H85.1L85.1,67.3L85.1,67.3z M85.1,132.7H104c14.5,0,21.8-4.6,21.8-13.5 c0-8.8-7.8-13.5-23-13.5H85.1V132.7L85.1,132.7L85.1,132.7z" })] })));
}
exports.default = Svg;
