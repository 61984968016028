"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 200 200" }, props, { children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("style", { children: ".cls-1{fill:#5fca8e;}.cls-2{fill:#3d9ff8;}" }) }), (0, jsx_runtime_1.jsxs)("g", __assign({ id: "Guidelines" }, { children: [(0, jsx_runtime_1.jsx)("path", { className: "cls-1", d: "M140,57.48c0,10.62,0,21.25,0,31.88,0,6.12-2.1,8.16-8.23,8.17-6.35,0-12.7,0-19,0-5,0-7.18-2.25-7.27-7.32-.06-3.54.06-7.09,0-10.63-.12-4.84-2.84-6.34-6.91-3.75C87.28,83,76.13,90.17,64.94,97.3c-1.19.76-2.41,2.26-3.92,1.54s-.8-2.65-.81-4c-.07-9.3,0-18.59-.05-27.89a11.35,11.35,0,0,1,6.34-10.8q33.06-17.93,66.1-35.88c4.67-2.53,7.33-.88,7.35,4.46C140,35.64,140,46.56,140,57.48Z" }), (0, jsx_runtime_1.jsx)("path", { className: "cls-2", d: "M60.17,142.48q0-15.72,0-31.43c0-6.68,1.93-8.56,8.74-8.58,6.35,0,12.7-.07,19,0,4.67.07,6.69,2.15,6.8,6.8.08,3.53,0,7.08,0,10.62.1,5.31,2.82,6.86,7.34,4.14,9.85-5.93,19.65-12,29.52-17.87,1.93-1.15,3.9-3.46,6.34-2s2,4.38,2,6.87c0,7.08-.09,14.16,0,21.24.09,5.4-2,9.22-6.84,11.84-21.8,11.74-43.51,23.64-65.28,35.44-5.15,2.78-7.68,1.18-7.7-4.79Q60.15,158.64,60.17,142.48Z" })] }))] })));
}
exports.default = Svg;
